import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.css']
})
export class ServicesSectionComponent implements OnInit {

  features = [
    {
      image: '../../../assets/images/forLandingPage/scan.png',
      heading: 'QR Scan Payment',
      description: 'Convenient Peer to Peer QR Code Payments with RAAST ID.'
    },
    {
      image: '../../../assets/images/forLandingPage/nfc.png',
      heading: 'NFC Payment',
      description: 'Secure transactions using NFC on your wearable gadgets.'
    },
    {
      image: '../../../assets/images/forLandingPage/smartwatch.png',
      heading: 'Wearable Gadget',
      description: 'Tap and Pay through Wristbands for Instant Retail Transactions.'
    },
    {
      image: '../../../assets/images/forLandingPage/installment.png',
      heading: 'Installment Plans',
      description: 'Shop now and pay later with our installment feature.'
    },
    {
      image: '../../../assets/images/forLandingPage/stock.png',
      heading: 'Digital Stock Trading',
      description: 'Trade stocks on the go with our user-friendly app and smart stock analysis.'
    },
    {
      image: '../../../assets/images/forLandingPage/atm-card.png',
      heading: 'Debit to Debit Transfer',
      description: 'Instantly Transfer Funds with Secure VISA Transactions.'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
