import { Component } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: './app.register.component.html',
  styleUrls: ['../../assets/layout/css/register.css']
})
export class AppRegisterComponent {

  fullName: string = '';
  phoneNumber: string = '';
  email: string = '';
  companyUrl: string = '';
  paymentVolume: number | null = null;
  additionalInfo: string = '';

  onSubmit() {
    // Implement your registration logic here (e.g., send data to a backend API).
    console.log('Full Name:', this.fullName);
    console.log('Phone Number:', this.phoneNumber);
    console.log('Email Address:', this.email);
    console.log('Company URL:', this.companyUrl);
    console.log('Payment Volume:', this.paymentVolume);
    console.log('Additional Information:', this.additionalInfo);
  }

}
