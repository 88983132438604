import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['../../assets/layout/css/login.css']
})
export class AppLoginComponent {

  email: string = '';
  password: string = '';

  constructor(private router: Router) {}

  navigateToRoute() {
    this.router.navigate(['/accounts']);
  }

  onSubmit() {
    console.log('Email:', this.email);
    console.log('Password:', this.password);
  }

}
