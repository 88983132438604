import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding-service/onboarding.service';

@Component({
  selector: 'app-business-information',
  templateUrl: './business-information.component.html',
  styleUrls: ['../../../assets/layout/css/onboarding-content.css']
})

export class BusinessInformationComponent {
  selectedDuration: string = '';
  selectedArea = [];
  deliveryOption = [];
  onlinePresenceStatus: string[] = [];
  website: string = '';

  onlinePresence = [
    { label: 'Website', value: 'Website', selected: false },
    { label: 'Facebook / Instagram Profile', value: 'Facebook / Instagram Profile', selected: false },
    { label: 'Freelancer', value: 'Freelancer', selected: false },
  ];

  serviceAreas = [
    { label: 'Inside Pakistan', value: 'Inside Pakistan', selected: false },
    { label: 'Outside Pakistan', value: 'Outside Pakistan', selected: false },
  ];

  durations: {value: string, label: string}[] = [
    { value: 'Less than 1 year', label: 'Less than 1 year' },
    { value: '2 - 3 years', label: '2 - 3 years' },
    { value: 'Over 3 years', label: 'Over 3 years' },
  ];

  deliveryOptions: {value: string, label: string}[] = [
    { value: 'Courier Company (e.g. TCS, Leopard, M&P, Swyft)', label: 'Courier Company (e.g. TCS, Leopard, M&P, Swyft)' },
    { value: 'Self Delivery (e.g. Bykea, Foodpanda, own vehicle)', label: 'Self Delivery (e.g. Bykea, Foodpanda, own vehicle)' },
    { value: 'Online Service - no delivery required', label: 'Online Service - no delivery required' },
  ];

  constructor(private router: Router, private onboardingService: OnboardingService) {}

  onAreaSelection(area: any) {
    if (area.selected) {
      if (!this.selectedArea.includes(area.value)) {
        this.selectedArea.push(area.value);
      }
    } else {
      const index = this.selectedArea.indexOf(area.value);
    }
  }

  onPresenceChange(presence: any) {
    if (presence.selected) {
      this.onlinePresenceStatus.push(presence.value);
    } else {
      const index = this.onlinePresenceStatus.indexOf(presence.value);
      // if (index !== -1) {
      //   this.selectedServices.splice(index, 1);
      // }
    }
  }

  onDeliveryOptionChange(option: any) {
    if (option.selected) {
      if (!this.deliveryOption.includes(option.value)) {
        this.deliveryOption.push(option.value);
      }
    } else {
      const index = this.deliveryOption.indexOf(option.value);
      if (index !== -1) {
        this.deliveryOption.splice(index, 1);
      }
    }
  }

  onBack() {
    this.router.navigate(['onboarding/address-details']);
  }

  onNext() {
    const data = { 
      duration: this.selectedDuration,
      serviceArea: this.selectedArea,
      deliveryOptions: this.deliveryOption,
      onlinePresence: this.onlinePresenceStatus,
      websiteLink: this.website
    };
    this.onboardingService.saveStepData(data);
    this.onboardingService.activeOptionIndex = 4;
    this.router.navigate(['onboarding/transaction-details']);
  }
}
