import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding-service/onboarding.service';

@Component({
  selector: 'app-business-owners',
  templateUrl: './business-owners.component.html',
  styleUrls: ['../../../assets/layout/css/onboarding-content.css']
})

export class BusinessOwnersComponent {
  businessOwnerData = {
    name: '',
    email: '',
    cnic: '',
    cnicExpiry: ''
  };
  businessOwners: any[] = [];
  showAddForm: boolean = false;

  constructor(private router: Router, private onboardingService: OnboardingService) {}

  toggleAddForm() {
    this.showAddForm = !this.showAddForm;
  }

  addBusinessOwner() {
    if (this.businessOwnerData.name && this.businessOwnerData.email && this.businessOwnerData.cnic && this.businessOwnerData.cnicExpiry) {
      const newOwner = { ...this.businessOwnerData };
      this.businessOwners.push(newOwner);

      this.businessOwnerData = {
        name: '',
        email: '',
        cnic: '',
        cnicExpiry: ''
      };
      this.showAddForm = false;
    } else {
      alert('Please fill in all fields.');
    }
  }

  cancelAddOwner() {
    this.businessOwnerData = {name: '', email: '', cnic: '', cnicExpiry: ''};
    this.showAddForm = false;
  }
  
  onBack() {
    this.router.navigate(['onboarding/transaction-details']);
  }

  onNext() {
    const data = { businessOwners: this.businessOwners };
    this.onboardingService.saveStepData(data);
    this.onboardingService.activeOptionIndex = 6;
    this.router.navigate(['onboarding/bank-account']);
  }
}
