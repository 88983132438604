import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features-section',
  templateUrl: './features-section.component.html',
  styleUrls: ['./features-section.component.css']
})
export class FeaturesSectionComponent implements OnInit {

  features = [
    {
      image: '../../../assets/images/card_payment.png',
      heading: 'Card Payments',
      description: "Rapid Gateway redefines e-commerce transactions with its first-rate card payment feature. Businesses leveraging this capability can provide their customers with a secure and efficient payment method, ensuring smooth and reliable transactions. Whether customers are using credit or debit cards, Rapid Gateway's card payment feature offers a seamless and trustworthy experience."
    },
    {
      image: '../../../assets/images/digital_wallets.png',
      heading: 'Digital Wallets',
      description: "Enhancing convenience for both businesses and customers, Rapid Gateway facilitates payments through digital wallets. This feature ensures a swift and frictionless checkout process, as users can securely complete transactions with just a few clicks. By supporting popular digital wallets, Rapid Gateway aligns with evolving consumer preferences and expectations."
    },
    {
      image: '../../../assets/images/installment_plan.png',
      heading: 'Installment Plans',
      description: "In addition to traditional payment options, Rapid Gateway introduces a game-changing installment plan feature. This functionality caters to a diverse customer base by allowing them to spread their payments over a period, providing financial flexibility and making high-value purchases more accessible. Merchants can attract and retain a broader audience, fostering increased customer loyalty."
    },
    {
      image: '../../../assets/images/third_party.png',
      heading: 'Third-Party Integration',
      description: "Recognizing the importance of seamless integration, Rapid Gateway offers third-party integration capabilities. This feature empowers businesses to connect and synchronize with external services effortlessly. Whether it's integrating with analytics tools, marketing platforms, or other business-critical services, Rapid Gateway's third-party integration feature streamlines operations and enhances overall efficiency."
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
