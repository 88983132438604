import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding-service/onboarding.service';

@Component({
  selector: 'app-business-details',
  templateUrl: './business-details.component.html',
  styleUrls: ['../../../assets/layout/css/onboarding-content.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class BusinessDetailsComponent {
  businessName: string = '';
  selectedIndustry: string = '';
  selectedServices: any = [];
  legalBusinessName: string = '';
  serviceCheckout: Number = 0;
  serviceQuickLinks: Number = 0;
  
  services = [
    { label: 'Checkout', value: 'checkout', selected: false },
    { label: 'Payment Links', value: 'payment_links', selected: false },
  ];

  industries: {value: string, label: string}[] = [
    { value: 'Food/Restaurants', label: 'Food/Restaurants' },
    { value: 'Fashion/Apparel', label: 'Fashion/Apparel' },
    { value: 'Travel and Tourism', label: 'Travel and Tourism' },
    { value: 'Digital Payments/Finance', label: 'Digital Payments/Finance' },
    { value: 'Service Industry', label: 'Service Industry' },
    { value: 'Variety Shop/Convenience Store', label: 'Variety Shop/Convenience Store' },
    { value: 'Schools and Education Services', label: 'Schools and Education Services' },
    { value: 'Other', label: 'Other' },
  ];

  businessType: {value: string, label: string}[] = [
    { value: 'Individual', label: 'Individual' },
    { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
    { value: 'Public Limited', label: 'Public Limited' },
    { value: 'Private Limited', label: 'Private Limited' },
    { value: 'Partnership', label: 'Partnership' },
    { value: 'NGO/Trust Foundation', label: 'NGO/Trust Foundation' },
  ];

  businessStatus: {value: string, label: string}[] = [
    { value: 'Unregistered Business', label: 'Unregistered Business' },
    { value: 'Registered Business', label: 'Registered Business' },
  ];

  constructor(private router: Router, private onboardingService: OnboardingService) {}

  onServiceChange(service: any): void {
    console.log('Selected Services:', this.selectedServices);
  
    if (!this.selectedServices.includes(service)) {
      this.selectedServices.push(service);
    } else {
      // const index = this.selectedServices.findIndex(item => item.label === service.label);
      // if (index !== -1) {
      //   this.selectedServices.splice(index, 1);
      // }
    }
  }

  onNext() {
    const data = { 
      businessName: this.businessName,
      serviceCheckout: this.selectedServices.includes('checkout') ? 1 : 0,
      serviceQuickLinks: this.selectedServices.includes('payment_links') ? 1 : 0,
      legalBusinessName: this.legalBusinessName,      
    };
    this.onboardingService.setNextOptionIndex();
    this.onboardingService.activeOptionIndex = 1;
    this.onboardingService.saveStepData(data);
    this.router.navigate(['onboarding/contact-details']);
  }
}
