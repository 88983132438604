import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding-service/onboarding.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['../../../assets/layout/css/onboarding-content.css']
})
export class ContactDetailsComponent {
  generalEmail: string = '';
  supportEmail: string = '';
  disputesEmail: string = '';
  phoneNumber: string = '';

  constructor(private router: Router, private onboardingService: OnboardingService) {}

  onBack() {
    this.router.navigate(['onboarding/business-details']);
  }

  onNext() {
    const data = { 
      generalEmail: this.generalEmail,
      supportEmail: this.supportEmail,
      disputesEmail: this.disputesEmail,
      phoneNumber: this.phoneNumber
    };
    this.onboardingService.setNextOptionIndex();
    this.onboardingService.activeOptionIndex = 2;
    this.onboardingService.saveStepData(data);
    this.router.navigate(['onboarding/address-details']);
  }
}
