import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding-service/onboarding.service';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['../../../assets/layout/css/onboarding-content.css']
})

export class BankAccountComponent {
  isModalOpen: boolean = false;
  selectedBankName: string = '';
  accountNumber: string = '';
  branchCode: string = '';

  banks = [
    { label: 'Bank Alfalah', value: 'Bank Alfalah' },
    { label: 'Meezan Bank', value: 'Meezan Bank' },
    { label: 'Bank Al Habib', value: 'Bank Al Habib' },
  ];

  constructor(private router: Router, private onboardingService: OnboardingService) {}

  openBankAccountModal() {
    this.isModalOpen = true;
  }

  closeBankAccountModal() {
    this.isModalOpen = false;
  }

  onValidate(data: any) {
    console.log('Validation Data:', data);

    this.closeBankAccountModal();
  }

  onBack() {
    this.router.navigate(['onboarding/business-owners']);
  }

  onNext() {
    const data = { 
      bankName: this.selectedBankName,
      accountNumber: this.accountNumber,
      branchCode: this.branchCode
    };
    this.onboardingService.saveStepData(data);
    this.onboardingService.activeOptionIndex = 7;
    this.router.navigate(['onboarding/document-verification']);
  }
}
