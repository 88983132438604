import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';

@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
    `,
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppMainComponent) { }

    ngOnInit() {
        this.model = [
            {label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/dashboard']},
            {label: 'Onboarding', icon: 'pi pi-fw pi-user-plus', routerLink: ['/dashboard/onboarding']},
            // {label: 'Plugins', icon: 'pi pi-briefcase', routerLink: ['/plugins']},
            {label: 'Transactions Data', icon: 'pi pi-dollar', routerLink: ['/dashboard/customers']},
            {label: 'User Management', icon: 'pi pi-fw pi-users', routerLink: ['/dashboard/user-management']},
            // {label: 'Transactions', icon: 'pi pi-chart-bar', routerLink: ['/transactions']},
            {
                label: 'Invoices', icon: 'pi pi-fw pi-tag', routerLink: ['/dashboard/invoices'],
                items: [
                    {label: 'Create', icon: 'pi pi-star', routerLink: ['/invoices']},
                    {label: 'List', icon: 'pi pi-star', routerLink: ['/invoices']},
                    {label: 'Bulk Upload', icon: 'pi pi-star', routerLink: ['/invoices']},
                ]
            },
            {
                label: 'Settings', icon: 'pi pi-cog', routerLink: ['/dashboard/settings']
            },
            {
                label: 'Documentation', icon: 'pi pi-fw pi-info-circle', routerLink: ['/dashboard/documentation']
            }
        ];
    }
}
