import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public sharedFunction(): void {
    // Your function logic here
    console.log('Shared function called!');
  }
}
