import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { OnboardingService } from '../services/onboarding-service/onboarding.service';

@Component({
  selector: 'app-onboarding-sidebar',
  templateUrl: './app.onboarding-sidebar.component.html',
  styleUrls: ['./app.onboarding-sidebar.component.css']
})

export class AppOnboardingSidebarComponent implements OnInit {
  options: { label: string, completed: boolean, path: string }[];
  activeOptionIndex: number = 0;

  constructor(
    private onboardingService: OnboardingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }
  
  ngOnInit() {
    this.options = this.onboardingService.options;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.activeOptionIndex = this.onboardingService.activeOptionIndex;
        this.cdr.detectChanges();
      });
  }

  isCompleted(index: number): boolean {
    return index < this.activeOptionIndex;
  }

  currentOption(index: number): boolean {
    return index == this.activeOptionIndex;
  }

  updateOptionStatus(index: number) {
    for (let i = 0; i < this.options.length; i++) {
      this.options[i].completed = i < index;
    }
  }

  getActiveOptionIndex(): number {
    const currentRoute = this.activatedRoute.root;
    const children = currentRoute.children;

    if (children.length > 0) {
      const currentChild = children[0];
      const path = currentChild.snapshot.routeConfig?.path;

      const activeOptionIndex = this.options.findIndex(
        (option) => option.path === `/${path}`
      );

      return activeOptionIndex !== -1 ? activeOptionIndex : 0;
    }

    return 0;
  }

  onNext() {
    if (this.activeOptionIndex < this.options.length - 1) {
      this.activeOptionIndex++;
      this.updateOptionStatus(this.activeOptionIndex);
    }
  }

  onBack() {
    if (this.activeOptionIndex > 0) {
      this.activeOptionIndex--;
      this.updateOptionStatus(this.activeOptionIndex);
    }
  }
}
