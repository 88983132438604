import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding-service/onboarding.service';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['../../../assets/layout/css/onboarding-content.css']
})

export class TransactionDetailsComponent {
  transactionsCount: number;
  avgAnnualSales: number;
  avgMonthlySales: number;
  avgTransactionSize: number;

  constructor(private router: Router, private onboardingService: OnboardingService) {}

  onBack() {
    this.router.navigate(['onboarding/business-information']);
  }

  onNext() {
    const data = { 
      transactionsCount: this.transactionsCount,
      avgAnnualSales: this.avgAnnualSales,
      avgMonthlySales: this.avgMonthlySales,
      avgTransactionSize: this.avgTransactionSize
    };
    this.onboardingService.saveStepData(data);
    this.onboardingService.activeOptionIndex = 5;
    this.router.navigate(['onboarding/business-owners']);
  }
}
