import { Component } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    constructor(public app: AppMainComponent, private router: Router, private route: ActivatedRoute) { }
    sidebarButtonEnabled = true;

    ngOnInit() {
        this.router.events.subscribe(() => {
          this.updateSidebarButtonState();
        });
    }

    isOnboardingRoute(): boolean {
        return this.route.snapshot.url.some(segment => segment.path === 'onboarding');
    }
    
      updateSidebarButtonState() {
        const currentRoute = this.route.snapshot.routeConfig.path;
        
        if (currentRoute.includes('/onboarding')) {
          this.sidebarButtonEnabled = false;
          this.app.onMenuButtonClick(null);
        } else {
          this.sidebarButtonEnabled = true;
        }
      }
}
