import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-page',
  templateUrl: './MainWeb.component.html',
//   styleUrls: ['./landing-page.component.css']
})
export class MainPage implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
