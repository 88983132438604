import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-page',
  templateUrl: './payment-solutions.component.html',
  styleUrls: ['./payment-solutions.component.css']
})

export class PaymentSolutionsPage implements OnInit {

  features = [
    {
      image: '../../../assets/images/qr.png',
      heading: 'Store QR Scan',
      description: "Customers simply use their mobile devices to scan a unique QR code generated by the merchant or payment provider. This QR code typically contains transaction details such as the payment amount and recipient information. Once scanned, the customer's mobile payment app securely processes the transaction, deducting the specified amount from their account and transferring it to the merchant. This quick and contactless method offers convenience and speed at the point of sale, reducing the need for physical payment cards or cash."
    },
    {
      image: '../../../assets/images/pos.png',
      heading: 'POS System Integration',
      description: "This integration allows merchants to accept various forms of payments, including credit cards, debit cards, and mobile payments, directly through their POS terminal. By connecting with payment processors or gateways, the POS system can securely handle transactions, verify payments, and automatically update inventory records. This integration enhances the overall efficiency of the checkout process, providing a smooth and convenient experience for both merchants and customers while maintaining accurate and real-time transaction data."
    },
    {
      image: '../../../assets/images/mobile_app.png',
      heading: 'Rapid Gateway Mobile App',
      description: "Users can initiate transactions seamlessly, leveraging the app's integration with various payment methods. The app ensures a rapid and hassle-free experience by securely processing payments, whether through credit cards, digital wallets, or other supported methods. With user-friendly interfaces and robust security measures, the Rapid Gateway. Enhance conversion rates by seamlessly integrating payment acceptance within the merchant's own applications, ensuring a smooth and convenient experience for customers."
    },
    {
      image: '../../../assets/images/smart_pos.png',
      heading: 'All-in-One Smart POS',
      description: "This integrated system combines traditional point-of-sale capabilities with advanced payment processing. Users can enjoy a seamless transaction experience, accepting diverse payment methods such as credit cards, debit cards, contactless payments, and mobile wallets. The All-in-One Smart POS not only simplifies the checkout process but also provides features like inventory."
    },
    // {
    //   image: '../../../assets/images/hero__image.png',
    //   heading: 'Web Payment',
    //   description: "Enhance conversion rates by seamlessly integrating payment acceptance within the merchant's own applications, ensuring a smooth and convenient experience for customers."
    // },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
