import { Component, ElementRef, ViewChild } from '@angular/core';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-customers',
  templateUrl: './app.customers.component.html',
  styleUrls: ['../../assets/layout/css/add-customers.css']
})
export class AppCustomersComponent {

  @ViewChild('fileInput') fileInput: ElementRef;

  csvData: any[][] = [];
  combinedData: any[];
  selectedRows: any[] = [];
  transaction_date: string = '';
  tracking_id: string = '';
  order_id: string = '';
  
  toggleSelection(row: any) {
    const index = this.selectedRows.indexOf(row);
    if (index === -1) {
      this.selectedRows.push(row);
    } else {
      this.selectedRows.splice(index, 1);
    }
    console.log("Selected IDs to Delete: ", this.selectedRows)
  }

  deleteSelectedRows() {
    for (const row of this.selectedRows) {
      const index = this.customers.indexOf(row);
      if (index !== -1) {
        this.customers.splice(index, 1);
      }
    }
    this.selectedRows = [];
  }

  constructor(private papa: Papa) {
    this.combinedData = this.csvData.concat(this.customers);
  }
  
  customers = [
    { name: 'Rizwan Ahmed', email: 'rizwan.ahmed@example.com', 'Payment Method': '4224********2345', 'Total Spend': '$50', 'Refunds': '0', 'Dispute Losses': '0', created: '2023-11-15, 11:07 AM' },
    { name: 'Lautaro Martinez', email: 'rizwan.ahmed@example.com', 'Payment Method': '4224********2345', 'Total Spend': '$70', 'Refunds': '0', 'Dispute Losses': '0', created: 'Jun 11, 11:07 AM' },
    { name: 'Rizwan Ahmed', email: 'rizwan.ahmed@example.com', 'Payment Method': '4224********2345', 'Total Spend': '$1283', 'Refunds': '0', 'Dispute Losses': '0', created: 'Jun 11, 11:07 AM' },
    { name: 'Rizwan Ahmed', email: 'rizwan.ahmed@example.com', 'Payment Method': '4224********2345', 'Total Spend': '$218', 'Refunds': '0', 'Dispute Losses': '$47', created: 'Jun 11, 11:07 AM' },
    { name: 'Rizwan Ahmed', email: 'rizwan.ahmed@example.com', 'Payment Method': '4224********2345', 'Total Spend': '$50', 'Refunds': '0', 'Dispute Losses': '0', created: 'Jun 11, 11:07 AM' },
    { name: 'Rizwan Ahmed', email: 'rizwan.ahmed@example.com', 'Payment Method': '4224********2345', 'Total Spend': '$429', 'Refunds': '0', 'Dispute Losses': '0', created: 'Jun 11, 11:07 AM' },
    { name: 'Rizwan Ahmed', email: 'rizwan.ahmed@example.com', 'Payment Method': '4224********2345', 'Total Spend': '$50', 'Refunds': '0', 'Dispute Losses': '0', created: 'Jun 11, 11:07 AM' },
    { name: 'Rizwan Ahmed', email: 'rizwan.ahmed@example.com', 'Payment Method': '4224********2345', 'Total Spend': '$50', 'Refunds': '$213.50', 'Dispute Losses': '0', created: '2023-11-2, 11:07 AM' },
  ];

  newCustomer: any = { name: '', email: '', phone: '', description: '', created: 'OCT 21, 11:07 AM' };

  filteredCustomers: any[] = this.customers;

  openAddCustomerModal() {
    document.getElementById('addCustomerModal')!.style.display = 'block';
  }

  closeAddCustomerModal() {
    document.getElementById('addCustomerModal')!.style.display = 'none';
  }

  pageSize: number = 6;
  currentPage: number = 1;
  totalCustomers: number = this.customers.length;

  getPaginatedCustomers(): any[] {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    return this.filteredCustomers.slice(startIndex, startIndex + this.pageSize);
  }

  nextPage() {
    if (this.currentPage * this.pageSize < this.totalCustomers) {
      this.currentPage++;
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  deselect() {
    this.selectedRows = [];
  }

  selectAllRows(checked: boolean) {
    if (checked) {
      this.selectedRows = this.getPaginatedCustomers();
    } else {
      this.selectedRows = [];
    }
  }

  getColumnHeaders(data: any): string[] {
    return Object.keys(data);
  }
   
  exportToCSV() {
    const dataCsv = this.papa.unparse(this.combinedData, { header: true });
    console.log(dataCsv);

    const blob = new Blob([dataCsv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'customersData.csv';
    a.click();

    window.URL.revokeObjectURL(url);
  }

  isFilterPopoverVisible = false;

  toggleFilterPopover() {
    this.isFilterPopoverVisible = !this.isFilterPopoverVisible;
  }

  closeFilterPopover() {
    this.isFilterPopoverVisible = false;
  }

  filter() {
    console.log(this.transaction_date);
    this.isFilterPopoverVisible = false;

    this.filteredCustomers = this.combinedData.filter((customer) => {
      const dateMatch = this.transaction_date
        ? customer['created']?.includes(this.transaction_date)
        : true;
      const trackingIdMatch = this.tracking_id
        ? customer['Tracking ID']?.includes(this.tracking_id)
        : true;
      const orderIdMatch = this.order_id
        ? customer['Order ID']?.includes(this.order_id)
        : true;
      return dateMatch && trackingIdMatch && orderIdMatch;
    });
    console.log(this.filteredCustomers)
  }

  removeFilter(option: string) {
    if(option === 'date') {
      this.transaction_date = '';
    } else if (option === 'tracking') {
      this.tracking_id = '';
    } else {
      this.order_id = '';
    }

    this.filter();
  }
}
