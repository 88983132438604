import { Component } from '@angular/core';

@Component({
  selector: 'app-plugins',
  templateUrl: './app.plugins.component.html',
  styleUrls: ['../../assets/layout/css/plugin.css']
})
export class AppPluginsComponent {

  plugins = [
    { image: './assets/images/woocommerce.png', title: 'WooCommerce' },
    { image: './assets/images/wordpress.png', title: 'Wordpress' },
    { image: './assets/images/shopify.png', title: 'Shopify' },
    { image: './assets/images/instacart.png', title: 'Instacart' },
    { image: './assets/images/magento.png', title: 'Magento' },
    { image: './assets/images/opencart.png', title: 'OpenCart' },
  ];

}
