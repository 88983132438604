import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding-service/onboarding.service';

interface BusinessType {
  type: string;
  documents: { name: string; required: boolean, up: string, down: string}[];
}

@Component({
  selector: 'app-document-verification',
  templateUrl: './document-verification.component.html',
  styleUrls: ['../../../assets/layout/css/onboarding-content.css']
})

export class DocumentVerificationComponent {
  documentData: { [key: string]: File } = {};
  cnicFrontImage: string | null = null;
  cnicBackImage: string | null = null;
  selectedBusinessType: string = 'individual';
  
  businessTypes = [
    {
      type: 'individual',
      documents: [
        { name: 'Business Address Document', required: true, up: '(paid utility bills e.g. electricity, telephone etc. Max file size 5MB)', down: '' },
        { name: 'CNIC Front Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC Back Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' }
      ]
    },
    {
      type: 'soleProprietorship',
      documents: [
        { name: 'Business Address Document', required: true, up: '(paid utility bills e.g. electricity, telephone etc. Max file size 5MB)', down: '' },
        { name: 'CNIC Front Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC Back Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'Business Owner’s CNIC', required: true, up: '(use 1 file for all sides. Max file size 5MB)', down: '' }
      ]
    },
    {
      type: 'publicLimited',
      documents: [
        { name: 'Business Address Document', required: true, up: '(paid utility bills e.g. electricity, telephone etc. Max file size 5MB)', down: '' },
        { name: 'Registration Certificates', required: false, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC Front Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC Back Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC of All Partners', required: true, up: '(use 1 file for all CNIC images. Max file size 5MB)', down: '' },
        { name: 'Memorandum & Article of Association', required: true, up: '(use single file for all. Max file size 5MB)', down: '' },
        { name: 'CNIC of All Directors', required: true, up: "(use 1 file for all directors' CNIC. Max file size 5MB)", down: '' }
      ]
    },
    {
      type: 'privateLimited',
      documents: [
        { name: 'Business Address Document', required: true, up: '(paid utility bills e.g. electricity, telephone etc. Max file size 5MB)', down: '' },
        { name: 'Registration Certificates', required: false, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC Front Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC Back Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC of All Partners', required: true, up: '(use 1 file for all CNIC images. Max file size 5MB)', down: '' },
        { name: 'Memorandum & Article of Association', required: true, up: '(use single file for all. Max file size 5MB)', down: '' },
        { name: 'CNIC of All Directors', required: true, up: "(use 1 file for all directors' CNIC. Max file size 5MB)", down: '' }
      ]
    },
    {
      type: 'partnership',
      documents: [
        { name: 'Business Address Document', required: true, up: '(paid utility bills e.g. electricity, telephone etc. Max file size 5MB)', down: '' },
        { name: 'Registration Certificates', required: false, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC Front Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC Back Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC of All Partners', required: true, up: '(use 1 file for all CNIC images. Max file size 5MB)', down: '' },
        { name: 'Registered Partnership Deed/ Copy of Form C', required: true, up: '(use 1 file. Max file size 5MB)', down: '' },
      ]
    },
    {
      type: 'ngos',
      documents: [
        { name: 'Business Address Document', required: true, up: '(paid utility bills e.g. electricity, telephone etc. Max file size 5MB)', down: '' },
        { name: 'Registration Certificates', required: false, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC Front Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC Back Side', required: true, up: '(image files jpg/png or PDF documents/Word Documents. Max file size 5MB)', down: '' },
        { name: 'CNIC of All Partners', required: true, up: '(use 1 file for all CNIC images. Max file size 5MB)', down: '' },
        { name: 'CNIC of all Authorize Person(s) by Trustees', required: true, up: '(use 1 file. Max file size 5MB)', down: '' },
        { name: 'Resolution', required: true, up: 'Resolution duly signed by Authorized Person(s) (use single file for all. Max file size 5MB)', down: '' },
        { name: 'Trust Deed', required: true, up: 'Trust Deed duly signed by all trustees (use single file for all. Max file size 5MB)', down: '' }
      ]
    },
  ];

  businessType: {value: string, label: string}[] = [
    { value: 'individual', label: 'Individual' },
    { value: 'soleProprietorship', label: 'Sole Proprietorship' },
    { value: 'publicLimited', label: 'Public Limited' },
    { value: 'privateLimited', label: 'Private Limited' },
    { value: 'partnership', label: 'Partnership' },
    { value: 'ngos', label: 'NGO/Trust Foundation' },
  ];

  onImageSelected(documentType: string, event: any): void {
    const file = event.target.files[0];
  
    switch (documentType) {
      case 'cnicFrontImage':
        this.cnicFrontImage = file;
        break;
      case 'cnicBackImage':
        this.cnicBackImage = file;
        break;
      default:
        console.error('Invalid document type.');
        break;
    }
  }

  onSubmit(): void {
    if (this.cnicFrontImage && this.cnicBackImage) {
      console.log(this.cnicFrontImage);
    } else {
      console.error('Both CNIC images are required.');
    }
  }

  openImageInNewTab(file: File) {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = reader.result as string;
        const newWindow = window.open();
        newWindow.document.write(`<img src="${dataURL}" alt="CNIC Front Image" />`);
      };
      reader.readAsDataURL(file);
    }
  }

  viewImage(documentType: string) {
    console.log(this.documentData)
    if (this.documentData[documentType]) {
      const imageUrl = URL.createObjectURL(this.documentData[documentType]);
      window.open(imageUrl, '_blank');
    } else {
      alert(`No ${documentType} image uploaded.`);
    }
  }

  constructor(private router: Router, private onboardingService: OnboardingService) {}

  onBack() {
    this.router.navigate(['onboarding/bank-account']);
  }

  onNext() {
    const data = { documentData: this.documentData };
    this.onboardingService.saveStepData(data);
    this.onboardingService.activeOptionIndex = 8;
    this.router.navigate(['onboarding/summary']);
  }

  private getBusinessType(): BusinessType | undefined {
    return this.businessTypes.find(type => type.type === this.selectedBusinessType);
  }
}
