import { Component } from '@angular/core';
import { OnboardingService } from 'src/app/services/onboarding-service/onboarding.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['../../../assets/layout/css/onboarding-content.css']
})
export class SummaryComponent {

  constructor(private onboardingService: OnboardingService) {}

  onboardingCompleted() {
    const onboardingData = this.onboardingService.getOnboardingData();
    // const onboardingData = {};
    console.log(onboardingData);

    this.onboardingService.submitOnboardingForm(onboardingData).subscribe(
      (response) => {
        console.log('Form submitted successfully:', response);
      },
      (error) => {
        console.error('Error submitting form:', error);
      }
    );
  }

}
