import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-management',
  templateUrl: './app.user-management.component.html',
  styleUrls: ['../../assets/layout/css/user-management.css']
})

export class UserManagementComponent {

    users = [
        { name: 'Rizwan Ahmed', roles: ['Admin', 'Manager', 'Auditor'] },
        { name: 'Muddasir Shabbir', roles: ['Auditor'] },
        { name: 'Muhammad Hamza Khan', roles: ['Manager'] }
    ];

    options = ['Admin', 'Manager', 'Auditor']
    
    selectAllUsers(event: Event) {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox: HTMLInputElement) => {
          checkbox.checked = (event.target as HTMLInputElement).checked;
        });
    }
    
    selectUser(user: any, event: Event) {
        user.selected = (event.target as HTMLInputElement).checked;
    }

    openAddCustomerModal() {
      document.getElementById('addCustomerModal')!.style.display = 'block';
    }
  
    closeAddCustomerModal() {
      document.getElementById('addCustomerModal')!.style.display = 'none';
    }
}
