import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.css']
})
export class AboutPageComponent implements OnInit {

  aboutData = [
    {
      image: '../../../../assets/images/vision.png',
      heading: 'Vision Statement',
      description: "Our goal is to become the industry leader in fintech innovation by offering cutting-edge payment options that break down barriers and improve online purchasing everywhere. With an unwavering dedication to security, usability, and technological innovation, our goal is to become the reliable enabler for companies looking to realize their full potential in the digital economy."
    },
    {
      image: '../../../../assets/images/mission.png',
      heading: 'Mission Statement',
      description: "Rapid Gateway's mission is to completely transform the e-commerce payment industry by offering companies cutting-edge, safe, and intuitive payment plugins. Our goal is to make online transactions less complicated by providing creative solutions that work well with a range of e-commerce platforms. Our dedication to quality, client pleasure, and ongoing technology development enables companies of all sizes to prosper in the digital age by promoting expansion, dependability, and achievement in each and every deal."
    },
    // {
    //   image: '../../../../assets/images/about__bg.png',
    //   heading: 'Shop Smart and Stay Stress-Free',
    //   description: 'The rapid pay installment feature lets you indulge in what you love now and pay later with flexible payment options. Our app allows users to split payments into manageable installments making high-value purchases more accessible and affordable. So, no more sacrificing your favorite items or waiting for the right moment to make a purchase. Enjoy the freedom of purchasing your favorite item by using our installment payment feature.',
    // },
    // {
    //   image: '../../../../assets/images/about__bg.png',
    //   heading: 'Effortless Payments with Wearable Gadgets',
    //   description: "Wearable gadgets such as wristbands will be used by rapid pay to transfer funds to retailers. Now you don’t need to fumble for your wallet or dig through your bag for your smartphone when it's time to pay. Our wearable payment system comes equipped with robust security features, including encryption and tokenization, ensuring that your payment information remains protected throughout every transaction. Try it today and discover a world where payment convenience knows no bounds.",
    // },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
