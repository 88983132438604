import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding-service/onboarding.service';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['../../../assets/layout/css/onboarding-content.css']
})

export class AddressDetailsComponent {

  streetAddress: string = '';
  selectedProvince: string = '';
  selectedCity: string = '';
  selectedDistrict: string = '';

  provinces: {value: string, label: string}[] = [
    { value: 'Sindh', label: 'Sindh' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'KPK', label: 'KPK' },
    { value: 'Balochistan', label: 'Balochistan' },
  ];
  
  provincesWithCities: { province: string, cities: { value: string, label: string }[] }[] = [
    {
      province: 'Punjab',
      cities: [
        { value: 'Lahore', label: 'Lahore' },
        { value: 'Faisalabad', label: 'Faisalabad' },
        // Add more cities for Punjab as needed
      ]
    },
    {
      province: 'Sindh',
      cities: [
        { value: 'Karachi', label: 'Karachi' },
        { value: 'Hyderabad', label: 'Hyderabad' },
        // Add more cities for Sindh as needed
      ]
    },
    {
      province: 'KPK',
      cities: [
        { value: 'Peshawar', label: 'Peshawar' },
        // Add more cities for KPK as needed
      ]
    },
    {
      province: 'Balochistan',
      cities: [
        { value: 'Quetta', label: 'Quetta' },
        // Add more cities for Balochistan as needed
      ]
    },
  ];
  
  districts: { city: string, districts: { value: string, label: string }[] }[] = [
    {
      city: 'Lahore',
      districts: [
        { value: 'lahore_district', label: 'Lahore District' },
        { value: 'sheikhupura_district', label: 'Sheikhupura District' },
        // Add more districts for Lahore as needed
      ]
    },
    {
      city: 'Faisalabad',
      districts: [
        { value: 'faisalabad_district', label: 'Faisalabad District' },
        // Add more districts for Faisalabad as needed
      ]
    },
    {
      city: 'Karachi',
      districts: [
        { value: 'karachi_district', label: 'Karachi District' },
        { value: 'malir_district', label: 'Malir District' },
        // Add more districts for Karachi as needed
      ]
    },
    // Add more cities with districts as needed
  ];

  filteredCities = [];
  filteredDistricts = [];

  constructor(private router: Router, private onboardingService: OnboardingService) { }

  onBack() {
    this.router.navigate(['onboarding/contact-details']);
  }

  onNext() {
    const data = {
      streetAddress: this.streetAddress,
      provinceId: this.selectedProvince,
      cityId: this.selectedCity,
      districtId: this.selectedDistrict
    };
    this.onboardingService.saveStepData(data);
    this.onboardingService.activeOptionIndex = 3;
    this.router.navigate(['onboarding/business-information']);
  }

  filterCities() {
    const selectedProvinceData = this.provincesWithCities.find(p => p.province === this.selectedProvince);
  
    if (selectedProvinceData) {
      this.filteredCities = selectedProvinceData.cities;
    } else {
      this.filteredCities = [];
    }
  
    this.selectedCity = ''; // Reset selected city when province changes
    this.filteredDistricts = []; // Reset filtered districts
  }

  // Function to filter districts based on the selected city
  filterDistricts() {
    const selectedCityData = this.provincesWithCities.reduce((acc, province) => {
      const city = province.cities.find(c => c.value === this.selectedCity);
      return city ? acc.concat(city) : acc;
    }, []);
  
    if (selectedCityData.length > 0) {
      const selectedCityDistricts = this.districts.find(d => d.city === selectedCityData[0].value);
  
      if (selectedCityDistricts) {
        this.filteredDistricts = selectedCityDistricts.districts;
      } else {
        this.filteredDistricts = [];
      }
    } else {
      this.filteredDistricts = [];
    }
  }  
}
