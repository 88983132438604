import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { NetworkLayerService } from '../NetworkService/network-layer.service';
import { RepsositoryService } from '../RepositoryService/repository-service';

@Injectable({
  providedIn: 'root',
})

export class OnboardingService {
  private onboardingEndpoint = 'merchant/registration';
  private onboardingData: any = {};
  private localStorageKey = 'onboardingData';
  
  private activePageSubject = new BehaviorSubject<string>('');
  public activePage$ = this.activePageSubject.asObservable();

  options: { label: string, completed: boolean, path: string }[] = [
    { label: 'Business Details', completed: true, path: '/#/onboarding/business-details' },
    { label: 'Contact Details', completed: true, path: '/#/onboarding/contact-details' },
    { label: 'Address Details', completed: true, path: '/#/onboarding/address-details' },
    { label: 'Business Information', completed: true, path: '/#/onboarding/business-information' },
    { label: 'Transaction Details', completed: false, path: '/#/onboarding/transaction-details' },
    { label: 'Business Owners', completed: false, path: '/#/onboarding/business-owners' },
    { label: 'Bank Account', completed: false, path: '/#/onboarding/bank-account' },
    { label: 'Document Verification', completed: false, path: '/#/onboarding/document-verification' },
    { label: 'Summary', completed: false, path: '/#/onboarding/summary' }
  ];

  constructor(private repositoryService: RepsositoryService) {}

  updateOnboardingData(onboardingData: any): Observable<any> {
    return this.repositoryService.post(this.onboardingEndpoint, onboardingData);
  }

  submitOnboardingForm(onboardingData: any): Observable<any> {
    return this.repositoryService.post(this.onboardingEndpoint, onboardingData);
  }

  getOnboardingData(): any {
    return this.onboardingData;
  }

  setActivePage(pageName: string) {
    this.activePageSubject.next(pageName);
  }
  
  activeOptionIndex = 0;

  setActiveOption(optionNumber: number): void {
    this.activeOptionIndex = optionNumber;
  }

  setNextOptionIndex() {
    this.activeOptionIndex++;
  }

  saveStepData(data: any): void {
    this.onboardingData = { ...this.onboardingData, ...data };
    console.log(this.onboardingData);
    this.saveOnboardingDataLocally(this.onboardingData);
  }

  private saveOnboardingDataLocally(data: any): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(data));
  }
}
