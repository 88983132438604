import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-account',
  templateUrl: './app.select-account.component.html',
  // styleUrls: ['../../assets/layout/css/*.css']
})

export class AppSelectAccountComponent {

  constructor(private router: Router) {}

  navigateToRoute() {
    this.router.navigate(['/onboarding']);
  }

}
