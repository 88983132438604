import { Component } from '@angular/core';
import { OnboardingService } from '../services/onboarding-service/onboarding.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './app.onboarding.component.html',
  styleUrls: ['../../assets/layout/css/onboarding.css']
})

export class AppOnboardingComponent {
  constructor(private OnboardingService: OnboardingService) {}

  onNextClick(page: string) {
    this.OnboardingService.setActivePage(page);
  }

}
