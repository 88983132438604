import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';
import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {InputDemoComponent} from './demo/view/inputdemo.component';
import {FloatLabelDemoComponent} from './demo/view/floatlabeldemo.component';
import {InvalidStateDemoComponent} from './demo/view/invalidstatedemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {DisplayComponent} from './utilities/display.component';
import {ElevationComponent} from './utilities/elevation.component';
import {FlexboxComponent} from './utilities/flexbox.component';
import {GridComponent} from './utilities/grid.component';
import {IconsComponent} from './utilities/icons.component';
import {WidgetsComponent} from './utilities/widgets.component';
import {SpacingComponent} from './utilities/spacing.component';
import {TypographyComponent} from './utilities/typography.component';
import {TextComponent} from './utilities/text.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppTimelineDemoComponent} from './pages/app.timelinedemo.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
import { AppRegisterComponent } from './pages/app.register.component';
import { AppSelectAccountComponent } from './pages/app.select-account.component';
import { AppOnboardingComponent } from './pages/app.onboarding.component';
import { BusinessDetailsComponent } from './pages/onboarding/business-details.component';
import { ContactDetailsComponent } from './pages/onboarding/contact-details.component';
import { AddressDetailsComponent } from './pages/onboarding/address-details.component';
import { BusinessInformationComponent } from './pages/onboarding/business-information.component';
import { TransactionDetailsComponent } from './pages/onboarding/transaction-details.component';
import { BusinessOwnersComponent } from './pages/onboarding/business-owners.component';
import { BankAccountComponent } from './pages/onboarding/bank-account.component';
import { DocumentVerificationComponent } from './pages/onboarding/document-verification.component';
import { AppPluginsComponent } from './pages/app.plugins.component';
import { AppCustomersComponent } from './pages/app.customers.component';
import { UserManagementComponent } from './pages/app.user-management.component';
import { SummaryComponent } from './pages/onboarding/summary.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component.';
import { AboutPageComponent } from './pages/landing-page/about-page/about-page.component';
import { MainPage } from './pages/landing-page/MainWeb.component';
import { HelpPageComponent } from './pages/landing-page/help-page/help-page.component';
import { SecurityPage } from './pages/landing-page/security-page/security-page.component';
import { PaymentSolutionsPage } from './pages/landing-page/payment-solutions-page/payment-solutions.component';
import { LoginComponent } from './pages/landing-page/login/login.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: MainPage,
                children: [
                    {path: '', component: LandingPageComponent},
                    {path: 'about', component: AboutPageComponent},
                    {path: 'payment-solutions', component: PaymentSolutionsPage},
                    {path: 'security', component: SecurityPage},
                    {path: 'contact', component: HelpPageComponent},
                    {path: 'login', component: LoginComponent},
                    {path: 'register', component: AppRegisterComponent}
                ]
            },
            {
                path: 'dashboard', component: AppMainComponent,
                children: [
                    {path: '', component: DashboardDemoComponent},
                    {path: 'uikit/formlayout', component: FormLayoutDemoComponent},
                    {path: 'uikit/floatlabel', component: FloatLabelDemoComponent},
                    {path: 'uikit/invalidstate', component: InvalidStateDemoComponent},
                    {path: 'uikit/input', component: InputDemoComponent},
                    {path: 'uikit/button', component: ButtonDemoComponent},
                    {path: 'uikit/table', component: TableDemoComponent},
                    {path: 'uikit/list', component: ListDemoComponent},
                    {path: 'uikit/tree', component: TreeDemoComponent},
                    {path: 'uikit/panel', component: PanelsDemoComponent},
                    {path: 'uikit/overlay', component: OverlaysDemoComponent},
                    {path: 'uikit/menu', component: MenusDemoComponent},
                    {path: 'uikit/media', component: MediaDemoComponent},
                    {path: 'uikit/message', component: MessagesDemoComponent},
                    {path: 'uikit/misc', component: MiscDemoComponent},
                    {path: 'uikit/charts', component: ChartsDemoComponent},
                    {path: 'uikit/file', component: FileDemoComponent},
                    {path: 'utilities/display', component: DisplayComponent},
                    {path: 'utilities/elevation', component: ElevationComponent},
                    {path: 'utilities/flexbox', component: FlexboxComponent},
                    {path: 'utilities/grid', component: GridComponent},
                    {path: 'utilities/icons', component: IconsComponent},
                    {path: 'utilities/widgets', component: WidgetsComponent},
                    {path: 'utilities/spacing', component: SpacingComponent},
                    {path: 'utilities/typography', component: TypographyComponent},
                    {path: 'utilities/text', component: TextComponent},
                    {path: 'pages/crud', component: AppCrudComponent},
                    {path: 'pages/calendar', component: AppCalendarComponent},
                    {path: 'pages/timeline', component: AppTimelineDemoComponent},
                    {path: 'pages/invoice', component: AppInvoiceComponent},
                    {path: 'pages/help', component: AppHelpComponent},
                    {path: 'pages/empty', component: EmptyDemoComponent},
                    {path: 'plugins', component: AppPluginsComponent},
                    {path: 'customers', component: AppCustomersComponent},
                    {path: 'documentation', component: DocumentationComponent},
                    {path: 'accounts', component: AppSelectAccountComponent},
                    {path: 'user-management', component: UserManagementComponent},
                    {
                        path: 'onboarding',
                        component: AppOnboardingComponent,
                        children: [
                            { path: '', redirectTo: 'business-details', pathMatch: 'full' },
                          { path: 'business-details', component: BusinessDetailsComponent },
                          { path: 'contact-details', component: ContactDetailsComponent },
                          { path: 'address-details', component: AddressDetailsComponent },
                          { path: 'business-information', component: BusinessInformationComponent },
                          { path: 'transaction-details', component: TransactionDetailsComponent },
                          { path: 'business-owners', component: BusinessOwnersComponent },
                          { path: 'bank-account', component: BankAccountComponent },
                          { path: 'document-verification', component: DocumentVerificationComponent },
                          { path: 'summary', component: SummaryComponent },
                        ],
                    },
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
